.movie-section{
    display: flex;
    justify-content: space-evenly;
    padding: 40px 0px;
    color:#FFFFFF;
    font-weight: 400;
    /* flex-wrap: wrap */

}
.movie-rating{
    padding-left: 3px;
    margin-top: 20px;
     color:#79b8f3;   
     display: flex;
}
.movie-rating span{
    margin-right: 20px;

}

.movie-title{
    font-size: 40px;
    color: #FFFFFF;

}

.movie-plot{
    margin-top: 20px;
    line-height: 1.8rem;
}


.movie-info > div span:first-child{
padding: 10px 0px;
color: #FFFFFF;
font-weight: 600;
width: 100px;
display: inline-block;
}
.movie-info > div span{
    color:#79b8f3;
}
.section-right{
    margin-left: 30px;
}

@media (max-width: 800px) {

    div.movie-section{
        display: block;
    }
    .section-right{
        margin-left: 10px;
        margin-top: 10px;
    
    }
}