.haeder{
    background-color: #1a242f;
    height: 72px;
    padding: 0px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo{
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
}
.user-image , .user-image img {
    width: 38px;
    height: 38px;
}
.search-bar{
    width: 50%;
    display: flex;
    justify-content: center;

}

.search-bar form{
    display: flex;
    justify-content: center;
    width: 70%;
   
}

.search-bar form button{
    padding: 0px 8px;
    font-size: 20px;
    cursor: pointer;
    height: 38px;
}
.search-bar input{
    font-size: 18px;
    width: 100%;
    padding: 5px 5px 5px 10px;
    height: 38px;
    outline: none;
    border: none;
}
