.movie-list ,.show-list {
    margin: 20px 0px;
}

.movie-list h2  ,.show-list h2 {
    color: #79b8f3;
    margin-bottom: 10px;
    font-weight: 400;
}

.movie-list h1  ,.show-list h1 {
    color: #ffffff;
    margin-bottom: 10px;
    font-weight: 400;
}