*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans",sans-serif;

  
}

a{
  text-decoration: none;
  color: #ffffff;
}
body{
  background: #0f171e;
}
.container{
  margin: 0 40px;

}